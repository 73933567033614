import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import DatePicker from "react-datepicker";
import { Accordion,Card,Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {CanvasJSReact} from '../assets/canvasjs.react';
import CorrImg from '../assets/images/doc-green-sign.jpg';
import WrgImg from '../assets/images/doc-red-sign.jpg';

import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
var XMLParser = require('react-xml-parser');
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

window.jQuery = $;
window.$ = $;
global.jQuery = $;
let jQuery=$;


// const appUrl="https://main.softwa.in/controller/softwaController.php?pm=";
// const appUrtPath="https://softwa.in/";
// const appWaUrlPath="https://main.softwa.in/softwa_upd/";

let _clAppUrl="https://adlog.ashtechnologies.in/extAppControl.php?pm=";
let _clAppUrtPath="https://ashtechnologies.in/";
let _clAppWaUrlPath="https://adlog.ashtechnologies.in/dtup/";

let _clAdlogUrl="https://adlog.fastrcdirect.com/";

let _footerTitle="Mgmt Panel";
let _metaTitle="Mgmt Panel";
let _logoPath="logo_p_small.png";

export const _setInitParam=()=>{
 
  let checkHost=window.location.hostname;
  if(checkHost==="adnew.fastrcdirect.com"){
    
    _clAppUrl="https://adlog.fastrcdirect.com/extAppControl.php?pm=";
     _clAppUrtPath="https://test.fastrcdirect.com/";
     _clAppWaUrlPath="https://adlog.fastrcdirect.com/dtup/";
     _clAdlogUrl="https://adlog.fastrcdirect.com/";
     _logoPath=_clAppUrtPath+"logo.png";
    _footerTitle="Deployed Version for FastRC Direct";

  }else if(checkHost==="adnew.mahamayasweets.com"){

    _clAppUrl="https://adlog.mahamayasweets.com/extAppControl.php?pm=";
    _clAppUrtPath="https://mahamayasweets.com/";
    _clAppWaUrlPath="https://adlog.mahamayasweets.com/dtup/";
    _clAdlogUrl="https://adlog.mahamayasweets.com/";
    _logoPath=_clAppUrtPath+"logo.png";
    _footerTitle="Deployed Version for Mahamaya Sweets";

  }else if(checkHost==="adnew.ayusharogyam.com"){
    _clAppUrl="https://www.ayusharogyam.com/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://www.ayusharogyam.com/";
    _clAppWaUrlPath="https://www.ayusharogyam.com/adlog/dtup/";
   _logoPath=_clAppUrtPath+"logo.png";
   _footerTitle="Deployed Version for AyushArogyam";
  }else if(checkHost==="adnew.narmadeayurvedam.com"){
    _clAppUrl="https://www.narmadeayurvedam.com/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://www.narmadeayurvedam.com/";
    _clAppWaUrlPath="https://www.narmadeayurvedam.com/adlog/dtup/";
   _logoPath=_clAppUrtPath+"logo.png";
   _footerTitle="Deployed Version for Narmade Ayurvedam";
  }
  else if(checkHost==="adminmui.ashtechnologies.in"){
    _clAppUrl="https://mui.ashtechnologies.in/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://mui.ashtechnologies.in/";
    _clAppWaUrlPath="https://mui.ashtechnologies.in/adlog/dtup/";
   _logoPath=_clAppUrtPath+"logo.png";
   _footerTitle="Deployed Version for MUI";
  }else if(checkHost==="adnew.bhandarkarpublications.com"){
    _clAppUrl="https://bhandarkarpublications.com/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://bhandarkarpublications.com/";
    _clAppWaUrlPath="https://bhandarkarpublications.com/adlog/dtup/";
   _logoPath=_clAppUrtPath+"logo.png";
   _footerTitle="Deployed Version for Bhandarkar Publications";
  }else if(checkHost==="adnew.mysticmansha.com"){
    _clAppUrl="https://test.mysticmansha.com/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://test.mysticmansha.com/";
    _clAppWaUrlPath="https://test.mysticmansha.com/adlog/dtup/";
   _logoPath=_clAppUrtPath+"logo.png";
   _footerTitle="Deployed Version for MysticMansha";
  }else if (checkHost==="adnew.rockenginesindia.com"){
    _clAppUrl="https://test.rockenginesindia.com/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://test.rockenginesindia.com/";
    _clAppWaUrlPath="https://test.rockenginesindia.com/adlog/dtup/";
   _logoPath=_clAppUrtPath+"logo.png";
   _footerTitle="Deployed Version for Bhandarkar Publications";
  }else if(checkHost==="adnew.ashtechnologies.in"){
    _clAppUrl="https://test.ashtechnologies.in/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://test.ashtechnologies.in/";
    _clAppWaUrlPath="https://test.ashtechnologies.in/adlog/dtup/";
   _logoPath=_clAppUrtPath+"logo.png";
   _footerTitle="Deployed Version for Ash Technologies";
  }else if(checkHost==="adnew.ujjainhotels.co.in"){
    _clAppUrl="https://ujjainhotels.co.in/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://ujjainhotels.co.in/";
    _clAppWaUrlPath="https://ujjainhotels.co.in/adlog/dtup/";
   _logoPath=_clAppUrtPath+"logo.png";
   _footerTitle="Deployed Version for UjjainHotels.co.in";
  }else if(checkHost==="adnew.dsbgroup.in"){
    _clAppUrl="https://adlog.dsbgroup.in/extAppControl.php?pm=";
    _clAppUrtPath="https://adlog.dsbgroup.in/";
    _clAppWaUrlPath="https://adlog.dsbgroup.in/dtup/";
    _logoPath=_clAppUrtPath+"logo.png";
    _footerTitle="Deployed Version for dsbgroup.in";
  }else if(checkHost==="adnew.dashoraandco.com"){
    _clAppUrl="https://adlog.dashoraandco.com/extAppControl.php?pm=";
    _clAppUrtPath="https://adlog.dashoraandco.com/";
    _clAppWaUrlPath="https://adlog.dashoraandco.com/dtup/";
    _logoPath=_clAppUrtPath+"logo.png";
    _footerTitle="Deployed Version for DashoraandCo.com";
  }else if(checkHost==="adnew.sfintech.in"){
    _clAppUrl="https://www.sfintech.in/adlog/extAppControl.php?pm=";
    _clAppUrtPath="https://www.sfintech.in/adlog/";
    _clAppWaUrlPath="https://www.sfintech.in/adlog/dtup/";
    _logoPath=_clAppUrtPath+"logo.png";
    _footerTitle="Deployed Version for DashoraandCo.com";
  }else if(checkHost==="adnew.ujjaindarshan.org"){
    
_clAppUrl="https://www.ujjaindarshan.org/adlog/extAppControl.php?pm=";
_clAppUrtPath="https://www.ujjaindarshan.org/";
_clAppWaUrlPath="https://www.ujjaindarshan.org/adlog/dtup/";
_logoPath=_clAppUrtPath+"logo.png";
_footerTitle="Deployed Version for UjjainDarshan.com";
  }
  else{
     //for SocietyofGlobalCycle
  //    _clAppUrl="https://societyofglobalcycle.com/adlog/extAppControl.php?pm=";
  //   _clAppUrtPath="https://societyofglobalcycle.com/";
  //   _clAppWaUrlPath="https://societyofglobalcycle.com/adlog/dtup/";
  //  _logoPath=_clAppUrtPath+"logo.png";
  //  _footerTitle="Deployed Version for Society of Global Cycle";

      //for manshaMishra
  //    _clAppUrl="https://test.mysticmansha.com/adlog/extAppControl.php?pm=";
  //   _clAppUrtPath="https://test.mysticmansha.com/";
  //   _clAppWaUrlPath="https://test.mysticmansha.com/adlog/dtup/";
  //  _logoPath=_clAppUrtPath+"logo.png";
  //  _footerTitle="Deployed Version for MysticMansha";
  
  //for UjjainHotels
//   _clAppUrl="https://ujjainhotels.co.in/adlog/extAppControl.php?pm=";
//   _clAppUrtPath="https://ujjainhotels.co.in/";
//   _clAppWaUrlPath="https://ujjainhotels.co.in/adlog/dtup/";
//  _logoPath=_clAppUrtPath+"logo.png";
//  _footerTitle="Deployed Version for UjjainHotels.co.in";

  //for UjjainHotels
  _clAppUrl="https://www.ujjaindarshan.org/adlog/extAppControl.php?pm=";
  _clAppUrtPath="https://www.ujjaindarshan.org/";
  _clAppWaUrlPath="https://www.ujjaindarshan.org/adlog/dtup/";
 _logoPath=_clAppUrtPath+"logo.png";
 _footerTitle="Deployed Version for UjjainDarshan.com";

// for dashora and company
// _clAppUrl="https://adlog.dashoraandco.com/extAppControl.php?pm=";
// _clAppUrtPath="https://adlog.dashoraandco.com/";
// _clAppWaUrlPath="https://adlog.dashoraandco.com/dtup/";
// _logoPath=_clAppUrtPath+"logo.png";
// _footerTitle="Deployed Version for DashoraandCo.com";

// for sfintech
// _clAppUrl="https://www.sfintech.in/adlog/extAppControl.php?pm=";
// _clAppUrtPath="https://www.sfintech.in/adlog/";
// _clAppWaUrlPath="https://www.sfintech.in/adlog/dtup/";
// _logoPath=_clAppUrtPath+"logo.png";
// _footerTitle="Deployed Version for DashoraandCo.com";

//DSB Group
// _clAppUrl="https://adlog.dsbgroup.in/extAppControl.php?pm=";
// _clAppUrtPath="https://adlog.dsbgroup.in/";
// _clAppWaUrlPath="https://adlog.dsbgroup.in/dtup/";
// _logoPath=_clAppUrtPath+"logo.png";
// _footerTitle="Deployed Version for dsbgroup.in";

    //for manshaMishra
  //   _clAppUrl="https://test.mysticmansha.com/adlog/extAppControl.php?pm=";
  //   _clAppUrtPath="https://test.mysticmansha.com/";
  //   _clAppWaUrlPath="https://test.mysticmansha.com/adlog/dtup/";
  //  _logoPath=_clAppUrtPath+"logo.png";
  //  _footerTitle="Deployed Version for MysticMansha";
    //for default mahamaya
    /*
    _clAppUrl="https://adlog.mahamayasweets.com/extAppControl.php?pm=";
    _clAppUrtPath="https://mahamayasweets.com/";
    _clAppWaUrlPath="https://adlog.mahamayasweets.com/dtup/";
    _logoPath=_clAppUrtPath+"logo.png";
    _footerTitle="Deployed Version for Mahamaya Sweets";
    */

    //for fastrcdirect
    // _clAppUrl="https://adlog.fastrcdirect.com/extAppControl.php?pm=";
    //  _clAppUrtPath="https://test.fastrcdirect.com/";
    //  _clAppWaUrlPath="https://adlog.fastrcdirect.com/dtup/";
    // _logoPath=_clAppUrtPath+"logo.png";
    // _footerTitle="Deployed Version for FastRC Direct";

    //for bhandarkar website main
  //   _clAppUrl="https://bhandarkarpublications.com/adlog/extAppControl.php?pm=";
  //   _clAppUrtPath="https://bhandarkarpublications.com/";
  //   _clAppWaUrlPath="https://bhandarkarpublications.com/adlog/dtup/";
  //  _logoPath=_clAppUrtPath+"logo.png";
  //  _footerTitle="Deployed Version for Bhandarkar Publications";

  // for narmade ayurvedam
//   _clAppUrl="https://www.narmadeayurvedam.com/adlog/extAppControl.php?pm=";
//   _clAppUrtPath="https://www.narmadeayurvedam.com/";
//   _clAppWaUrlPath="https://www.narmadeayurvedam.com/adlog/dtup/";
//  _logoPath=_clAppUrtPath+"logo.png";
//  _footerTitle="Deployed Version for Narmade Ayurvedam";



  // for ashtechnologies
//   _clAppUrl="https://test.ashtechnologies.in/adlog/extAppControl.php?pm=";
//   _clAppUrtPath="https://test.ashtechnologies.in/";
//   _clAppWaUrlPath="https://test.ashtechnologies.in/adlog/dtup/";
//  _logoPath=_clAppUrtPath+"logo.png";
//  _footerTitle="Deployed Version for Ash Technologies";

    //for Ayusharogyam
    // _clAppUrl="https://www.ayusharogyam.com/adlog/extAppControl.php?pm=";
    //  _clAppUrtPath="https://www.ayusharogyam.com/";
    //  _clAppWaUrlPath="https://www.ayusharogyam.com/adlog/dtup/";
    // _logoPath=_clAppUrtPath+"logo.png";
    // _footerTitle="Deployed Version for AyushArogyam";

    //for MUI

    // _clAppUrl="https://mui.ashtechnologies.in/adlog/extAppControl.php?pm=";
    //  _clAppUrtPath="https://mui.ashtechnologies.in/";
    //  _clAppWaUrlPath="https://mui.ashtechnologies.in/adlog/dtup/";
    // _logoPath=_clAppUrtPath+"logo.png";
    // _footerTitle="Deployed Version for MUI";


    //for Astrosaxena
    _clAppUrl="https://astrosaxena.com/process/call_auth.php?pm=";
     _clAppUrtPath="https://astrosaxena.com/";
     _clAppWaUrlPath="https://astrosaxena.com/control/gallery/";
    _logoPath=_clAppUrtPath+"logo.png";
    _footerTitle="Deployed Version for Astrosaxena";

    //  _clAppUrl="http://localhost:8086/astro_new/process/call_auth.php?pm=";
    //  _clAppUrtPath="http://localhost:8086/astro_new/";
    //  _clAppWaUrlPath="http://localhost:8086/astro_new/control/gallery/";
    // _logoPath=_clAppUrtPath+"logo.png";
    // _footerTitle="Deployed Version for Astrosaxena";


    //for RockEnginesIndia
  //   _clAppUrl="https://test.rockenginesindia.com/adlog/extAppControl.php?pm=";
  //   _clAppUrtPath="https://test.rockenginesindia.com/";
  //   _clAppWaUrlPath="https://test.rockenginesindia.com/adlog/dtup/";
  //  _logoPath=_clAppUrtPath+"logo.png";
  //  _footerTitle="Deployed Version for Bhandarkar Publications";
  }
  
}

_setInitParam();

export const _getInitParam=()=>{
  let initParam={"appUrl":_clAppUrl,
"domainUrl":_clAppUrtPath,
"fileUpdPath":_clAppWaUrlPath,
"logoPath":_logoPath,
"footerText":_footerTitle};
let data = require('./app_package.json');
if(typeof data[window.location.hostname]!=="undefined"){
  initParam.app_settings=data[window.location.hostname];
}

return initParam;
}


export const getAppUrl=()=>{
  return _clAppUrl;
}
export const appGetUrlPath=()=>{
  return _clAppUrtPath;
}
export const getWaUrlPath=()=>{
  return _clAppWaUrlPath;
}
export const getAdlogUrl=()=>{
  return _clAdlogUrl;
}


export const setProfilePic=(obj, bdata)=> {
  //alert(bdata);
  if(bdata!= null && bdata!=="0"){
    obj.src =  bdata;
  }
}


export const customAlert=(title,msg,urls,oks="0",yesno="0")=>{
   var buttons_=[];
   if(yesno!=="0"){
     buttons_= [
         {
           label: 'Yes',
           onClick: () => window.location.href=urls
         },
         {
           label: 'No',
           onClick: () => window.location.href=urls
         }
       ];
   }else if(oks!=="0"){
     if(urls!=="0"){
      buttons_=[
        {
          label: 'ok',
          onClick: () => window.location.href=urls
        }];
     }else{
      buttons_=[
        {
          label: 'ok',
        }];
     }
      
   }

   confirmAlert({
      title: title,
      message: msg,
      buttons: buttons_
    });
}

export function readURL(input) {
   if (input.files && input.files[0]) {
     //alert("i am read url");
       var reader = new FileReader();
       reader.onload = function (e) {
         //alert($(input).attr("ref_img"));
           $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
       }

       reader.readAsDataURL(input.files[0]);
   }
}

export const getAttendanceList=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["month_nm"]+"</td><td>P:["+lists[i]["Present"]+"]</td><td>A:["+lists[i]["Absents"]+
      "]</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table class=\"table table-sm table-borderless\"></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
}

export const GetTripReports=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      var strToClose="";
      if(lists[i]["tip_status"]==="Open"){
            strToClose="<tr><td style=\"color:red\">Not Completed: </td><td>"+
            "<a href=\"/OnTripComp?ref="+lists[i]["id"]+"\">Click to Mark as Completed</a></td></tr>"
      }
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["entry_date"]+"</td><td>Status :"+lists[i]["tip_status"]+"</td>"+
      "</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table  class=\"table table-sm table-borderless\"><tr>"+"<td>Started From :</td>"+
          "<td>"+lists[i]["trip_start_from"]+"</td></tr><tr>"+
          "<td>Initial Reading : </td>"+"<td>"+lists[i]["init_km_read"]+"</td></tr><tr>"+
          "<td>Destination To :</td>"+"<td>"+lists[i]["trip_desti_to"]+"</td></tr><tr>"+
          "<td>Trip End Km :</td>"+"<td>"+lists[i]["comp_km_read"]+"</td></tr><tr>"+
          "<td>Status :</td>"+"<td>"+lists[i]["tip_status"]+"</td>"+strToClose+
          "</tr></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
}

export const GetLeaveRequest=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["col1"]+"</td><td>"+lists[i]["date"]+"</td><td>"+lists[i]["status"]+
      "</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table  class=\"table table-sm table-borderless\"><tr><td colspan=\"3\">"+ lists[i]["details"]+"</td></tr>"+
          "<tr><td>From</td><td>To</td><td>Status</td></tr>"+
          "<tr>"+"<td>"+lists[i]["c_req_from"]+"</td>"+
          "<td>"+lists[i]["c_req_to"]+"</td>"+
          "<td>"+lists[i]["status"]+"</td>"+
          "</tr></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
   
}

export function GetGraphData(setData){
  
  //alert(JSON.stringify(titleg));
  const options = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "Result Score : "+setData.titleg+"%"
    },
          height:300,
    data: [{
      type: "pie",
              radius:"50%",
      startAngle: 30,
      toolTipContent: "<b>{label}</b>: {y}%",
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}%",
      dataPoints: setData.setData
    }]
  }
  return(
    <CanvasJSChart options = {options} />
  );
}

export function ConvertDateFormat_Time(inputDate,isDefault=true) {
  
  if(isDefault!==true){
// Parse the input date string into a Date object
let date = new Date(inputDate.replace(" ", "T"));

// Get the components of the date
let day = String(date.getDate()).padStart(2, '0');
let month = date.toLocaleString('default', { month: 'MMM' });
let year = date.getFullYear();
let hours = String(date.getHours()).padStart(2, '0');
let minutes = String(date.getMinutes()).padStart(2, '0');

// Format the date into dd/MMM/yyyy HH:mm
return `${day}-${month}-${year} ${hours}:${minutes}`;
  }else{
    // Parse the input date string into a Date object
    let date = new Date(inputDate);

    // Get the components of the date
    let day = String(date.getDate()).padStart(2, '0');
    let monthAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month = monthAbbr[date.getMonth()];
    let year = date.getFullYear();
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date into dd/MMM/yyyy HH:mm
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  


}

export function TableDatePicker(refName) {
   //alert(JSON.stringify(refName.refName));
   var refNm= refName.refName;
   const [date, setDate] = useState(new Date());
   let strDate=date;
  //  if(typeof $("#"+refNm)!=="undefined" && $("#"+refNm).val()!==""){
  //   strDate=$("#"+refNm).val();
  //  }
  //  alert("pickker date=="+parseD strDate);
  //
   return (
     <DatePicker className="form-control" dateFormat="dd/MM/yyyy" id={refNm} name={refNm}   format='dd/MM/yyyy' selected={date} onChange={date =>setDate(date)} />
   );
  }

export const SetResponseDiv=(divName,respTxt)=>{
   $("#"+divName).html(respTxt);
   return "done";
}

export const defaultClick=(obCls,appliedString)=>{
   $(appliedString).click(function(){
   var appFunction =$(this).attr("attEvt");
      obCls[appFunction]("test");
   });
   //return obCls[clFunction]();
}
export const GetCurrentDtTime=()=>{
  var currentDate=new Date();
  return currentDate.toJSON().slice(0,19);
}
export const GetDateDifference=(strDate)=>{
  var currentDate=new Date();
  var checkDate=new Date(strDate);
  const diffTime = Math.abs(currentDate - checkDate);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
 return diffDays; 
}
export const FormatDate=(input)=> {
  var datePart = input.match(/\d+/g),
  year = datePart[0].substring(0), // get only two digits
  month = datePart[1], day = datePart[2];

  return day+'/'+month+'/'+year;
}
export const SetDate=(date)=> {
   //z = $(date).attr('value');
  
   var today = new Date();
   var dd = today.getDate();
   var mm = today.getMonth() + 1; //January is 0!

   var yyyy = today.getFullYear();
   if (dd < 10) { dd = '0' + dd }
   if (mm < 10) { mm = '0' + mm }
   //today = yyyy + '-' + mm + '-' + dd;
   today = dd + '/' + mm + "/" + yyyy;
   //today=mm+"/"+dd+"/"+yyyy;
   //alert("check== " + today);
   $(date).text(today);
   //$(date).datepicker({dateFormate:'dd/mm/yy'}).val();
}

export const btnSetColor_CBack=(obCls,frmNm,curl,callback)=>{
    /* Validation */
    let frmName=frmNm;
    // alert(typeof ssFrm);
    let objElems=document.getElementById(frmName).getElementsByClassName("required");
    let isValidated=true;
    // alert(objElems.length);
    for(var r=0;r<objElems.length;r++){
      if(objElems[r].value==="" || objElems[r].value==="0"){
        objElems[r].style.border="1px solid #e87d1e";
        isValidated=false;
      }
    }
   if(!isValidated){
     return callback("not_validated","Fill blank records!"); 
   }

   //value objects
   ajaxindicatorstart("Loading Please Wait..");
  var ssFrm=$("#"+frmNm);
  var m_type= $(ssFrm).find('input[name="m_type"]').val();
  var curl_type=$(ssFrm).find('input[name="m_type"]').val();
  var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
   var ss = $("#"+frmNm).serializeArray();
   
   var xs = "{'objs':" + JSON.stringify(ss) + "}";
   //alert(xs);
  if(!getCallFunc){
getCallFunc="";

   }
   $.ajax({
    crossDomain: true,
        type: "POST",
     url: _clAppUrl + curl_type,
     data: xs,
     contentType: "application/json;charset=utf-8",
     dataType: "json",
     success: function (data) {
       ajaxindicatorstop();
        //alert(data.d);
         //ajaxindicatorstop();
            //alert("Registration Completed Successfully ");
            // document.getElementById("frmRegister").reset();
            //location.reload();
             if(getCallFunc!="")
             {
                var checkData=$($($.parseHTML(data.d)).filter("#resP")).text();
                var checkMsg=$($($.parseHTML(data.d)).filter("#resPS")).text();
          //  eval(getCallFunc);
          //alert(checkData);
                return callback(checkData,checkMsg);
             }else
             {
               //parent.$.colorbox.close();
            }
        }
    });

}

export const GetImgCert_CBack=(obCls,frmNm,curl,callback)=>{
  //alert(curl);
  var options={
    url     : _clAppUrl+curl,
    success : function(data)
    {
      ajaxindicatorstop();
      var resData=data.documentElement.childNodes[0].nodeValue;
     
      var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
     var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
    
       return callback(checkData,checkMsg);
        
 },
    error: function(data) {
      ajaxindicatorstop();
       return callback(0,"done");
     }
   };
ajaxindicatorstart("Loading Please Wait..");
 $(frmNm).ajaxSubmit( options );
}

export const GetImgCert=(obCls,frmNm,curl,resfun)=>
{
   var options={
      url     : _clAppUrl+curl,
      success : function(data)
      {
        
        ajaxindicatorstop();
        var resData=data.documentElement.childNodes[0].nodeValue;
        //alert(resData);
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
      
         return obCls[resfun](checkData,checkMsg);
          
   },
      error: function(data) {
        ajaxindicatorstop();
         return obCls[resfun](0,"done");
       }
     };
  ajaxindicatorstart("Loading Please Wait..");
   $(frmNm).ajaxSubmit( options );
}
export const RespBkMthdMapp=(obCls,htCls,cBack,groupwhen="")=>{
  if(groupwhen==="2"){
    $("."+htCls).click(function(){
      var refId=$(this).attr("param1");
      var refId2=$(this).attr("param2");
      
      if(typeof refId2!=="undefined" && typeof refId2!==false){
        
      }else{
        refId2="0";
      }

      let refId3="0";
      if(typeof $(this).attr("param3")!=="undefined"){
        refId3=$(this).attr("param3");
      }


      return obCls[cBack](refId,refId2,refId3);
   });
  }
  if(htCls==="custom-file-input"){
    //alert("I am in conidtion");
    $(".custom-file-input").change(function(){
      let fileName = $(this).val();
      let fileLable=$(this).attr("label");
      alert(fileLable);
      $("#"+fileLable).html(fileName);
    })
  }
  if(htCls==="filebr_name"){
   // $('#customFile').on('change', function(){ files = $(this)[0].files; name = ''; for(var i = 0; i < files.length; i++){ name += '\"' + files[i].name + '\"' + (i != files.length-1 ? ", " : ""); } $(".custom-file-label").html(name); });
   
  }
  if(htCls==="_setbackparam"){
    $("._setbackparam").unbind('click');
    $("._setbackparam").click(function(){
      //alert("i m in backparar");
      var refId=$(this).attr("param1");
      var refId2=$(this).attr("param2");
      let clkObject=$(this)[0];
      if(typeof refId2!=="undefined" && typeof refId2!==false){
        
      }else{
        refId2="0";
      }

      var resBack=$(this).attr("resback");
      if(typeof resBack!=="undefined" && typeof resBack!==false){
        //alert("i in click with ref");
        return obCls[resBack](refId,refId2,clkObject);
      }else{
        //alert("i in click with calsb");
        return obCls[cBack](refId,refId2,clkObject);
      }
      
   });
  }
  if(htCls==="select_file_ch"){
    $(".select_file_ch").change(function () {
    
     var nmFile= $(this).attr("param1");
     var URL = window.URL || window.webkitURL;
       var vidFile=this.files[0];
       var fileURL = URL.createObjectURL(vidFile);
      document.getElementById(nmFile).src=fileURL;
    });
  }
  if(htCls==="_setSearchKeyUp"){
   
    $("._setSearchKeyUp").keyup(function(e){
      var refId= $(this).val();
         return obCls[cBack](refId);
    });
  }
 if(htCls==="hid_file_ch"){
  var tabcontent = document.getElementsByClassName(htCls);
  return tabcontent;
  /*
  for(var t=0;t<tabcontent.length;t++){
   //alert("testing=="+tabcontent[t].value);
   var fileAttr=tabcontent[t].getAttribute("param1");
   return fileAttr;
   //alert(fileAttr+"=="+tabcontent[t].value);
   //document.getElementById(fileAttr).value=tabcontent[t].value;
  }
  */
 }else if(htCls="_addToCart"){
  $('.'+htCls).click(function(){
     //alert("I am in click");

     let ssFrm=$(this).closest('form');
     let getItem= localStorage.getItem("_cart");
     var items=[];
     if(getItem!=null && getItem!==""){
        getItem=JSON.parse(localStorage.getItem("_cart"));
     }else{
        getItem=[];
     }

     var itemName=$(ssFrm).find('input[name="item_name"]').val();
     var itemCode=$(ssFrm).find('input[name="item_code"]').val();
     var itemRate=$(ssFrm).find('input[name="item_rate"]').val();
     var compName=$(ssFrm).find('input[name="comp_name"]').val();
     var itemRfId=$(ssFrm).find('input[name="item_rf_id"]').val()
     var imgPath=$(ssFrm).find('input[name="imgs"]').val();
    
     items.push({"item_name":itemName,"item_code":itemCode,
     "item_rate":itemRate,"company_name":compName,"item_rf_id":itemRfId,"img_c":imgPath});
     for(var i=0;i<getItem.length;i++){
       //alert(getItem[i].item_name);
       items.push({"item_name":getItem[i].item_name,"item_code":getItem[i].item_code,
       "item_rate":getItem[i].item_rate,"company_name":getItem[i].company_name,"item_rf_id":getItem[i].item_rf_id,"img_c":getItem[i].img_c});
     }
    
     localStorage.setItem("_cart",JSON.stringify(items));
  
    if(cBack!=="nn" && cBack!=""){
     obCls[cBack](items.length);
    }
    });
} 
}

export const displayQuest=(index,divClsName)=>{
  var tabcontent = document.getElementsByClassName(divClsName);
  for (var i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  if(index!=-1){
    tabcontent[index].style.display="block";
  }
  
}

export const getHmProducts=(lists)=>{
  var strProd="";
  var getLists=JSON.parse(lists);
  var count=0;
  for(var i=0;i<getLists.length;i++){
    strProd+="<div class=\"col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
             "<div class=\"product\">"+
        "<div class=\"thumb\">"+
            "<a href=\"shop-left-sidebar.html\" class=\"image\">"+
                "<img src=\""+_clAppUrtPath+getLists[i]["img_path"]+"\" alt=\"Product\" />"+
                "<img class=\"hover-image\" src=\""+_clAppUrtPath+getLists[i]["img_path"]+"\" alt=\"Product\" />"+
            "</a>"+
            "<span class=\"badges\">"+
                "<span class=\"new\">New</span>"+
            "</span>"+
            "<div class=\"actions\">"+
                "<a href=\"wishlist.html\" class=\"action wishlist\" title=\"Wishlist\"><i class=\"icon-heart\"></i></a>"+
                "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                "<a href=\"compare.html\" class=\"action compare\" title=\"Compare\"><i class=\"icon-refresh\"></i></a>"+
            "</div>"+
            "<button title=\"Add To Cart\" class=\"add-to-cart\">Add To Cart</button>"+
        "</div>"+
        "<div class=\"content\">"+
            "<h5 class=\"title\"><a href=\"shop-left-sidebar.html\">"+getLists[i]["product_name"]+"</a></h5>"+
            "<span class=\"price\">"+
                "<span class=\"new\">Rs."+getLists[i]["cost"]+"</span>"+
            "</span>"+
        "</div>"+
    "</div>"+
    "</div>";
              }
              return strProd;
}

export const getAttemptQuestions=(lists)=>{
  var strQuet="";
  var getLists=JSON.parse(lists);
  var count=0;
  //alert(getLists.length);
  for(var i=0;i<getLists.length;i++){
    count=i+1;
   // var obList=$data['user_list'][$qk];
  var divName="step"+(count);
  var nextDiv="step"+(count+1);
  var preDiv="step"+(count-1);
  
  //var cName=getLists[i]["id"]+"__Q";
  //var hName= getLists[i]["id"]+"__H";
  //var correctAns=getLists[i]["col6"];
var wrongImg="<img alt=\"wrong\" src=\"/doc-red-sign.jpg\"/>";
var rightImg="<img alt=\"right\" src=\"/doc-green-sign.jpg\"/>";
var lbCustA="custom-control-label";
var lbCustB="custom-control-label";
var lbCustC="custom-control-label";
var lbCustD="custom-control-label";


var corColorA="";
var corColorB="";
var corColorC="";
var corColorD="";
if(getLists[i]["col3"]=="a"){
  corColorA= " correct_ans_color";
}else if(getLists[i]["col3"]=="b"){
  corColorB= " correct_ans_color";
}else if(getLists[i]["col3"]=="c"){
  corColorC= " correct_ans_color";
}else if(getLists[i]["col3"]=="d"){
  corColorD= " correct_ans_color";
}

if(getLists[i]["col2"]==getLists[i]["col3"]){
   if(getLists[i]["col2"]=="a"){
     lbCustA="custome-lable-right";
   }else if(getLists[i]["col2"]=="b"){
     lbCustB="custome-lable-right";
   }else if(getLists[i]["col2"]=="c"){
     lbCustC="custome-lable-right";
   }else if(getLists[i]["col2"]=="d"){
     lbCustD="custome-lable-right";
   }
}else{
  if(getLists[i]["col2"]=="a"){
    lbCustA="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="b"){
    lbCustB="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="c"){
    lbCustC="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="d"){
    lbCustD="custome-lable-wrong";
  }
}
    strQuet+="<div id=\""+divName+"\" style=\"display:block\"  class=\"tabcontent card border p-3 mt-3\">"+
    "<p class=\"ss-font-style-2\"><i className=\"icofont-listing-number\"></i>"+"Q."+count+")"+getLists[i]["question"]+"?"+"</p>"+
    "<div class=\"w-100\"></div>"+
    "<ul class=\"list-group\">"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustA+" "+corColorA+"\">"+getLists[i]["opt_a"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustB+" "+corColorB+"\">"+getLists[i]["opt_b"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustC+" "+corColorC+"\" for=\""+"c"+"\">"+getLists[i]["opt_c"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustD+" "+corColorD+"\" for=\""+"d"+"\">"+getLists[i]["opt_d"]+"</label>"+
        "</div>"+
      "</li>"+
    "</ul>"+
  "</div>";
  }
  return strQuet;
}



export const TimeLine=(lists)=>{
  var getLists=JSON.parse(lists);
  var strResp="";
  var strTimeL="";
  var strDivText="";
  var strImgText="";
  var strVidText="";
  var strOth="";
  for(var i=0;i<getLists.length;i++){
     strTimeL="";
     strDivText="";
     strImgText="";
     strVidText="";
     strOth="";
     var createdDate = new Date(getLists[i]["entry_date"]);
    strTimeL="<div class=\"time-label\">"+
     "<span class=\"bg-primary\">"+getLists[i]["entry_date"]+"</span>"+
   "</div>";
   strTimeL+="<div>"+
   "<i class=\"fa fa-comments bg-yellow\"></i>"+
   "<div class=\"timeline-item\">"+
   "<span class=\"time\"><i className=\"fa fa-clock\"></i> for self</span>"+
   "<h3 class=\"timeline-header\"><a href=\"#\">"+getLists[i]["col1"]+"</a></h3>";
   if(getLists[i]["details"]!==""){
    strDivText="<div class=\"timeline-body\">"+getLists[i]["details"]+"</div>";
   }
   if(getLists[i]["file_name"]!==""){
     var files=getLists[i]["file_name"];
     var arrFiles=files.split(',');
     for(var fi=0;fi<arrFiles.length;fi++){
       //alert(arrFiles[fi]);
       if(arrFiles[fi].toUpperCase().indexOf(".JPG")!=-1 || 
       arrFiles[fi].toUpperCase().indexOf(".PNG")!=-1 || arrFiles[fi].toUpperCase().indexOf(".JPEG")!=-1){
         if(strImgText==""){
           strImgText="<div class=\"timeline-body\">";
         }
        strImgText+="<img param1=\""+arrFiles[fi]+"\" height=\"100\" width=\"150\" class=\"hid_file_ch gallery-item\" alt=\"...\"/>";
       }else if(arrFiles[fi].toUpperCase().indexOf(".MP4")!=-1){
        
        if(strVidText==""){
           strVidText="<div class=\"timeline-body\">";
         }
         strVidText+="<video param1=\""+arrFiles[fi]+"\" id=\""+fi+getLists[i]["id"]+"\" class=\"hid_file_ch\" controls type=\"video/mp4\"></video>"; 
         //strVidText+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\"><i class=\"fa fa-play-circle-o fa-2x\" aria-hidden=\"true\"></i>"+arrFiles[fi]+"</a>";
       }else{
          if(strOth==""){
            strOth="<div class=\"timeline-body\">";
          }
          strOth+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\">"+arrFiles[fi]+"</a>";
       }
       
     }
     if(strImgText!==""){
       strImgText+="</div>";
     }
     if(strVidText!==""){
       strVidText+="</div>";
     }
     if(strOth!==""){
       strOth+="</div>";
     }

   }
   
   strResp+=strTimeL+strDivText+strOth+strImgText+strVidText;
   strResp+="</div></div>"
  }
  return strResp;
}
export const getSearchOutPut=(mainDiv,childDiv,searchText,isTable=false,tableName="")=>{
  if(!isTable){
    var cont=document.getElementById(mainDiv);
    var childList=cont.getElementsByClassName(childDiv);
    for(var i=0;i<childList.length;i++){
      //var fromSer=childList[i].querySelector(".timeline-item");
      var fromSer=childList[i];
      /*
      var index = fromSer.innerText.toUpperCase().indexOf(searchText.toUpperCase());
    if (index >= 0) { 
      //fromSer.innerHTML = fromSer.innerText.substring(0,index) + "<span class='highlight'>" +fromSer.innerText.substring(index,index+fromSer.innerText.length) + "</span>" + fromSer.innerText.substring(index + fromSer.innerText.length);
     //inputText.innerHTML = innerHTML;
    }
    */
  //alert(fromSer.innerText.toUpperCase());
      if(fromSer.innerText.toUpperCase().indexOf(searchText.toUpperCase())>-1){
           childList[i].style.display="";
      }else{
       childList[i].style.display="none";
      }
    }
  }else{
    //alert("i m in draw "+searchText);
    let oTable = $('#'+tableName).DataTable();   //pay attention to capital D, which is mandatory to retrieve "api" datatables' object, as @Lionel said
    oTable.search(searchText).draw() ;
    // $('#myInputTextField').keyup(function(){
    //       oTable.search($(this).val()).draw() ;
    // })
  }
  
}

export const getCheckRolesNew=()=>{
  let getObj=JSON.parse(localStorage.getItem("userData"));
  let listRoles=getObj.role;

  let getMenu="";
  for(let i=0;i<listRoles.length;i++){
    getMenu += "<div style=\"margin-bottom:1rem;\" class=\"row\"><div style=\"background:#efe6e5\" class=\"col-12\">" +
    "<div class=\"clearfix\"><div style=\"padding-left:1rem;\" class=\"icheck-primary d-inline\">" + "<input type=\"checkbox\" value=\"" + listRoles[i]["id"] + "\" name=\"" + listRoles[i]["id"] + "\" id=\"" + listRoles[i]["id"] + "\" />" + "<label for=\"" + listRoles[i]["id"]+"\">" +
    listRoles[i]["col1"] + "</label>" + "</div></div>" +
    "</div>";;
  }
  return getMenu;


}
export const validateNumberLength=(elementId,setL)=> {
  const input = document.getElementById(elementId);
  const maxLength = setL; // Maximum number of digits allowed
 
  if (input.value.length > maxLength) {
    input.value = input.value.slice(0, maxLength); // Truncate the input to the maximum length
  }
}
export const detectEmojisAndSpecialCharacters=(inputString)=>{
  // Emoji pattern
  const emojiPattern = /[\u{1F300}-\u{1F6FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F680}-\u{1F6FF}\u{1F30D}-\u{1F567}]/ug;

  // Special character pattern
  const specialCharPattern = /[^\w\s]/g;

  const emojis = inputString.match(emojiPattern);
  //const specialChars = inputString.match(specialCharPattern);
  const specialChars = [];

  return {
    emojis: emojis || [],
    specialCharacters: specialChars || []
  };
}

export const sortList=(listjs, prop, asc,isNumeric)=>{
  if(isNumeric===true){
    listjs.sort(function (a, b) {
      if (asc) {
        return parseInt(a[prop]) > parseInt(b[prop])
          ? 1
          : parseInt(a[prop]) < parseInt(b[prop])
          ? -1
          : 0
      } else {
        return parseInt(b[prop]) > parseInt(a[prop])
          ? 1
          : parseInt(b[prop]) < parseInt(a[prop])
          ? -1
          : 0
      }
    })
  }else{
    if(asc){
      listjs = listjs.sort((a, b) => {
        if (a[prop] < b[prop]) {
          return -1;
        }
      });
    }else{
      listjs = listjs.sort((a, b) => {
        if (a[prop] > b[prop]) {
          return -1;
        }
      });
    }
   
  }
  
  return listjs;

}


export const getAccessRolesNew=(roles)=>{
  //alert(JSON.stringify(roles));
   var getMenu="";
   const listFltr = d => ((d.display_option === 'menu' || d.display_option === 'top_menu') && ( d.parent_id==='0' || d.parent_id==='1'));
   var topMenusList = roles.filter(listFltr);
   //alert(topMenusList.length);
   for (var i = 0; i < topMenusList.length; i++) {
       const childFltr = d => d.parent_id === topMenusList[i]["id"] && d.display_option !== 'desk'
       var childMenuList = roles.filter(childFltr);
       var getChMenu = "";
       //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
       if (childMenuList.length === 0) {
           getMenu += " <li class=\"nav-item  d-sm-inline-block\">" +
               "<a href = \"" + topMenusList[i]["red_link"] + "\" class=\"nav-link nav-menu-top-item\">" + topMenusList[i]["name"] + "</a>" +
               "</li>";
       } else {
           getMenu += "<li class=\"nav-item dropdown\">" +
               "<a class=\"nav-link nav-menu-top-item dropdown-toggle\" href = \"#\" id=\"navbarDropdown_m"+i+"\" data-target=\"#navbarDropdown"+i+"\" role=\"button\" data-toggle=\"dropdown\" aria haspopup=\"true\" aria-expanded=\"true\">" + topMenusList[i]["name"] + "</a>"
           getChMenu = "<div id=\"navbarDropdown"+i+"\" class=\"dropdown-menu\" aria-labelledby=\"navbarDropdown\">";
           for (var ch = 0; ch < childMenuList.length; ch++) {
               getChMenu += "<a  class=\"dropdown-item\" href=\"" + childMenuList[ch]["red_link"] + "\">" + childMenuList[ch]["name"] + "</a>";
           }
           getChMenu += "</div>";
           getMenu += getChMenu + "</li>";
       }
 
       // getMenu += topMenusList[i]["col1"];
   }
   return getMenu;
  
 }


export const CallMapMethodRef=(obCls,frmValues,refObj)=>{
  var curl_type=frmValues['curl_type'];

    //alert("check url == "+curl_type);
    var divRes="";
    var getCallFunc=""
    if(frmValues['res_name'])
    {
      divRes=frmValues['res_name'];
    }

    if(frmValues['res_func'])
    {
      getCallFunc=frmValues['res_func'];
    }
        var ss=frmValues;
        //var xs = "{objs:" + JSON.stringify(ss) + "}";
       //alert(xs);
         $.ajax({
            crossDomain:true,
                type: "POST",
                url: _clAppUrl+curl_type,
                data:ss,
             success: function (data) {
              //alert(data);   
              //ajaxindicatorstop();
                 // return data;
                 if(divRes!="")
                 {
                     if ($("#" + divRes).length!=0)
                  $("#"+divRes).html(data);
                 }
                 if(getCallFunc!="")
                 {
                  var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                  var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                  //  eval(getCallFunc);
                  //alert(checkData);
                 return obCls[getCallFunc](checkData,checkMsg,refObj);
                    }
                   
                }
            });
}


export function isJSONParsable(str) {
  try {
      JSON.parse(str);
      return true;
  } catch (e) {
      return false;
  }
}

export const CallMapMethodJSON_CBack=(obCls,frmValues,callback)=>{
  var curl_type=frmValues['curl_type'];

  //alert("check url == "+curl_type);
  var divRes="";
  var getCallFunc=""
  if(frmValues['res_name'])
  {
    divRes=frmValues['res_name'];
  }

  if(frmValues['res_func'])
  {
    getCallFunc=frmValues['res_func'];
  }
      var ss=frmValues;
      let xs=ss;
      //var xs = "{'objs':" + JSON.stringify(ss) + "}";
     //alert(xs);
    
     
     ajaxindicatorstart("Loading Please Wait..");
       $.ajax({
          crossDomain:true,
              type: "POST",
              url: _clAppUrl+curl_type,
              data:xs,
              //contentType: "application/json;charset=utf-8",
              dataType: "json",
              complete: function (data) {
            ajaxindicatorstop();
            //alert(JSON.stringify(data));
            
            if(isJSONParsable(data["responseText"])){
              let pData=JSON.parse(data["responseText"])["data"];
              let pMsg=JSON.parse(data["responseText"])["msg"];
              //alert(JSON.stringify(pData));
              
                if(getCallFunc!="")
                {
                   callback(JSON.stringify(pData),pMsg);
                }
            }else{
              
var checkData=$($($.parseHTML(data)).filter("#resP")).text();
var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
callback(checkData,checkMsg);
            }
         
             
          }
      });
}

export const CallMapMethod_CBack=(obCls,frmValues,callback)=>{
  var curl_type=frmValues['curl_type'];

  //alert("check url == "+curl_type);
  var divRes="";
  var getCallFunc=""
  if(frmValues['res_name'])
  {
    divRes=frmValues['res_name'];
  }

  if(frmValues['res_func'])
  {
    getCallFunc=frmValues['res_func'];
  }
      var ss=frmValues;
      let xs=ss;
      //var xs = "{'objs':" + JSON.stringify(ss) + "}";
     //alert(xs);
    
     
     ajaxindicatorstart("Loading Please Wait..");
       $.ajax({
          crossDomain:true,
              type: "POST",
              url: _clAppUrl+curl_type,
              data:xs,
              //contentType: "application/json;charset=utf-8",
              //dataType: "json",
           success: function (data) {
            ajaxindicatorstop();
             //alert(data);
                //alert(data.d);
               //ajaxindicatorstop();
               // alert(data.d);
               // return data;0
             
               if(divRes!="")
               {
                   if ($("#" + divRes).length!=0)
                $("#"+divRes).html(data);
               }
               if(getCallFunc!="")
               {
                var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                //  eval(getCallFunc);
                  callback(checkData,checkMsg);
                  }
                 
              }
          });
}

export const CallMapMethod=(obCls,frmValues)=>{
   
  var curl_type=frmValues['curl_type'];

  //alert("check url == "+curl_type);
  var divRes="";
  var getCallFunc=""
  if(frmValues['res_name'])
  {
    divRes=frmValues['res_name'];
  }

  if(frmValues['res_func'])
  {
    getCallFunc=frmValues['res_func'];
  }
      var ss=frmValues;
      var xs = "{'objs':" + JSON.stringify(ss) + "}";
     //alert(xs);
       $.ajax({
          crossDomain:true,
              type: "POST",
              url: _clAppUrl+curl_type,
              data:xs,
              contentType: "application/json;charset=utf-8",
              dataType: "json",
           success: function (data) {
              //  alert(data.d);
               //ajaxindicatorstop();
               // alert(data.d);
               // return data;0
             
               if(divRes!="")
               {
                   if ($("#" + divRes).length!=0)
                $("#"+divRes).html(data);
               }
               if(getCallFunc!="")
               {
                var checkData=$($($.parseHTML(data.d)).filter("#resP")).text();
                var checkMsg=$($($.parseHTML(data.d)).filter("#resPS")).text();
                //  eval(getCallFunc);
               return obCls[getCallFunc](checkData,checkMsg);
                  }
                 
              }
          });
}
export const GetImgCertUser_CBack=(obCls,frmNm,curl,callback)=>{
  ajaxindicatorstart("Processed Please Wait..");
  var options={
    url     : _clAppUrl+curl,
    success : function(data)
    {
      ajaxindicatorstop();
      //alert(data);
      //var resData=data.documentElement.childNodes[0].nodeValue;
     var resData=data;
      var checkData=$($($.parseHTML(resData)).filter("#resP")).html();
     var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).html();
     //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
     var userMsg="";  
     return callback(checkData,checkMsg,userMsg);
        
 },
    error: function(data) {
      ajaxindicatorstop();
       return callback(0,JSON.stringify(data),"0");
     }
   };
   var strFrmNm=frmNm.substr(1);
   var isValidated=true;
   //alert(strFrmNm);
   var objElems=document.getElementById(strFrmNm).getElementsByClassName("required");
   for(var r=0;r<objElems.length;r++){
     
     if(objElems[r].value==="" || objElems[r].value==="0"){
       //alert(objElems[r].type);
       objElems[r].style.border="2px solid rgb(195 17 17)";
       isValidated=false;
     }
   }
   if(!isValidated){
    ajaxindicatorstop();
     return callback(-10,"Fill Required Fileds","");
   }else{
    //  alert(frmNm);
    $(frmNm).ajaxSubmit( options );
   }

 //$(frmNm).ajaxSubmit( options );

}
export const GetImgCertUser=(obCls,frmNm,curl,resfun)=>
{
   var options={
      url     : _clAppUrl+curl,
      success : function(data)
      {
        //alert(data);
        //var resData=data.documentElement.childNodes[0].nodeValue;
       var resData=data;
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
       //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
       var userMsg="";  
       return obCls[resfun](checkData,checkMsg,userMsg);
          
   },
      error: function(data) {
      
         return obCls[resfun](0,data);
       }
     };
  
   $(frmNm).ajaxSubmit( options );
 

}

export const setEventsLib=(eventName,needConfirm,callBack)=>{
  if(eventName=="editable"){
    $(".editable").click(function(event){
      if($(this).children("input").length > 0)
            return false;
  
      var tdObj = $(this);
      let e_key=$(this).attr("e_key");
      let e_id=$(this).attr("e_id");
      
      var preText = tdObj.html();
      var inputObj = $("<input type='text' />");
      tdObj.html("");
  
      inputObj.width(tdObj.width())
              .height(tdObj.height())
              .css({border:"0px",fontSize:"17px"})
              .val(preText)
              .appendTo(tdObj)
              .trigger("focus")
              .trigger("select");
  
      inputObj.keyup(function(event){
        if(13 == event.which || 9===event.which) { // press ENTER-key
          var text = $(this).val();
          tdObj.html(text);
          if(needConfirm==="confirm"){
            if(window.confirm("Kindly confirm edit")===true){
              callBack(e_id,e_key,text);
            }
            
          }else{
            callBack(e_id,e_key,text);
          }
          
          
        }
        else if(27 == event.which) {  // press ESC-key
          tdObj.html(preText);
        }
      });
  
      inputObj.click(function(){
        return false;
      });
    });
  }
}

export const AjaxCallMethod = (obCls,cBack)=>{
  $("._brFileThumb").unbind('click');
  $(".updImgThumb").unbind('change');

  $("._brFileThumb").click(function(){
    var getNm=$(this).attr("setTo");
    document.getElementById(getNm).click();
  });

  $(".updImgThumbMulti").change(function(){
    var currentObj=$(this);
    var refBack=$(this).attr("ref_img");
    var refFlName=this.value.split('\\').pop();

    let checkSize=$(this).attr("size");
    let filesCollect=[];
    //alert();
    //alert(this.files.length);
    if (this.files && this.files[0]) {

     for(let f=0;f<this.files.length;f++){
  //check size
  if (typeof checkSize !== 'undefined' && checkSize !== false) {
    if(this.files[f].size>checkSize){
      return obCls[refBack]("-10","Size larger then validated!",currentObj);
    }
}
  
  //alert("i am read url");
    var reader = new FileReader();
    reader.onload = function (e) {
      //alert("i m in file read");
      //var refBack=$(this).attr("ref_img");
      //alert(refBack);
      //alert(e.target.name);
      //filesCollect.push(e.target.result);
      //obCls[refBack](filesCollect,refFlName,currentObj);
      obCls[refBack](e.target.result,refFlName,currentObj);
      //alert($(input).attr("ref_img"));
       // $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
    }

    reader.readAsDataURL(this.files[f]);
     }
    }
    //alert(filesCollect.length);
      
  });

  $(".updImgThumb").change(function(){
    var currentObj=$(this);
    var refBack=$(this).attr("ref_img");
    var refFlName=this.value.split('\\').pop();

    let isMultiple=false;
    let checkMultiple=$(this).attr("multiple");
    //alert(checkMultiple);
    if(typeof checkMultiple!=="undefined"){
      
      isMultiple=true;
    }


    let checkSize=$(this).attr("size");
    //alert();
    //alert(refBack);
    if (this.files && this.files[0]) {
      //alert(this.files[0].size);
      //check size
      if (typeof checkSize !== 'undefined' && checkSize !== false) {
        if(this.files[0].size>checkSize){
          return obCls[refBack]("-10","Size larger then validated!",currentObj);
        }
    }

    let jvThis=$(this)[0];
    
    let thumbnail="";
    if(jvThis.hasAttribute("thumbnail")){
      thumbnail=jvThis.getAttribute("thumbnail");
    }
    //alert(thumbnail+" = "+refBack)

    let lsElement="";
    if(jvThis.hasAttribute("lsElement")){
      lsElement=jvThis.getAttribute("lsElement");
    }


      
      //alert("i am read url");
        var reader = new FileReader();
        reader.onload = function (e) {
          //var refBack=$(this).attr("ref_img");
          //alert(refBack);
          //alert(e.target.name);
          return obCls[refBack](e.target.result,refFlName,currentObj,"",lsElement,thumbnail,isMultiple);
          //alert($(input).attr("ref_img"));
           // $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
        }
 
        reader.readAsDataURL(this.files[0]);
    }
  });

  $('._brFile').click(function(){
     var getNm=$(this).attr("setTo");
     
     document.getElementById(getNm).click();
     });
  $(".updImg").change(function(){
     readURL(this);
     //alert("A file has been selected.");
 });
 $(".select_target").change(function () {
  var getCallFunc= $(this).attr("resp_dest");
  var refId=$(this).val();
  return obCls[getCallFunc](refId);
 });
 
 $('.keyEvent_btn').keyup(function (e) {
  if (e.keyCode == 13) {
      var refEvent = $(this).attr("rf");
      $(refEvent).click();
  }
});

   $('._btnSetColor').click(function () {
   	 //var m_type=$("#m_type").val();
    //var curl_type=$("#curl_type").val();
  //$("#modal_login").serialize();
  ajaxindicatorstart("Loading Please Wait..");
  var ssFrm=$(this).closest('form');
  var m_type= $(ssFrm).find('input[name="m_type"]').val();
  var curl_type=$(ssFrm).find('input[name="m_type"]').val();
  var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
   var ss = $(this).closest('form').serializeArray();

     /* Validation */
     let frmName=ssFrm.attr("id");
     // alert(typeof ssFrm);
     let objElems=document.getElementById(frmName).getElementsByClassName("required");
     let isValidated=true;
     // alert(objElems.length);
     for(var r=0;r<objElems.length;r++){
       if(objElems[r].value==="" || objElems[r].value==="0"){
         objElems[r].style.border="1px solid #e87d1e";
         isValidated=false;
       }
     }
    if(!isValidated){
      ajaxindicatorstop();
      return obCls[getCallFunc](-10,"Fill blank records!"); 
    }
   
   //var xs = "{'objs':" + JSON.stringify(ss) + "}";
   let xs=ss;
   //alert(xs);
  if(!getCallFunc){
getCallFunc="";

   }
   //alert(xs);
  
   //ajaxindicatorstart('loading data.. please wait..');
//alert(getCallFunc);
  //var ss=$("#"+fName).serialize();
        $.ajax({
           crossDomain: true,
               type: "POST",
            url: _clAppUrl + curl_type,
            data: xs,
            //contentType: "application/json;charset=utf-8",
            //dataType: "json",
            success: function (data) {
              ajaxindicatorstop();
               //alert(data);
                //ajaxindicatorstop();
                   //alert("Registration Completed Successfully ");
                   // document.getElementById("frmRegister").reset();
                   //location.reload();
                    if(getCallFunc!="")
                    {
                       var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                       var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                 //  eval(getCallFunc);
                 //alert(checkData);
                       return obCls[getCallFunc](checkData,checkMsg);
                    }else
                    {
                      //parent.$.colorbox.close();
                   }
               }
           });

});
}
export function ajaxindicatorstart(text) {
  if (jQuery('body').find('#resultLoading').attr('id') != 'resultLoading') {
      jQuery('body').append('<div id="resultLoading" style="display:none"><div><img src="../ajax-loader.gif"><div>' + text + '</div></div><div class="bg"></div></div>');
  }

  jQuery('#resultLoading').css({
      'width': '100%',
      'height': '100%',
      'position': 'fixed',
      'z-index': '10000000',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'margin': 'auto'
  });

  jQuery('#resultLoading .bg').css({
      'background': '#000000',
      'opacity': '0.7',
      'width': '100%',
      'height': '100%',
      'position': 'fixed',
      'top': '0',
      'margin-left': '0'
  });

  jQuery('#resultLoading>div:first').css({
      'width': '250px',
      'height': '75px',
      'text-align': 'center',
      'position': 'fixed',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'margin': 'auto',
      'font-size': '16px',
      'z-index': '99999999999',
      'color': '#ffffff'

  });

  jQuery('#resultLoading .bg').height('100%');
  jQuery('#resultLoading').fadeIn(300);
  jQuery('body').css('cursor', 'wait');
}

export function ajaxindicatorstop() {
  jQuery('#resultLoading .bg').height('100%');
  jQuery('#resultLoading').fadeOut(300);
  jQuery('body').css('cursor', 'default');
}
export default AjaxCallMethod